import React from "react";
import { WrapperContainer } from "../../../App-styled";
import Plant from "../../../assets/ForGarden/plant.png";
import Plant1 from "../../../assets/ForGarden/for.png";
import styled from "./style.module.css";
import { useTranslation } from "react-i18next";

function Gardening() {
  const { t } = useTranslation();
  return (
    <>
      <WrapperContainer>
        <div className={styled.Wrapper}>
          <img className={styled.mainImg} src={Plant1} alt="photo" />
          <div className={styled.texts}>
            <p className={styled.subtext}>
              <img src={Plant} alt="photo" /> <span>{t("Gardening.0")}</span>
            </p>
            <h2>{t("Gardening.1")}</h2>
            <p className={styled.longText}>{t("Gardening.2")}</p>
            <div className={styled.expi}>
              <ul>
                <li>
                  <div className={styled.icon}>
                    <ion-icon name="checkmark-outline"></ion-icon>
                  </div>
                  <p>{t("Gardening.3")}</p>
                </li>
                <li>
                  <div className={styled.icon}>
                    <ion-icon name="checkmark-outline"></ion-icon>
                  </div>
                  <p>{t("Gardening.4")}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </WrapperContainer>
    </>
  );
}

export default Gardening;
