import React from "react";
import styled from "./style.module.css";
import Plant from "../../../assets/ForGarden/plant.png";
import { WrapperContainer } from "../../../App-styled";
import Main from "../../../assets/Expi/Image.png";
import img1 from "../../../assets/Expi/1.png";
import img2 from "../../../assets/Expi/2.png";
import Looking from "./Looking";
import { useTranslation } from "react-i18next";

function Experience() {
  const { t } = useTranslation();
  return (
    <>
      <WrapperContainer>
        <div className={styled.Wrapper}>
          <div className={styled.texts}>
            <p className={styled.subtext}>
              <img src={Plant} alt="photo" />
              <span> {t("Expi.0")} </span>
            </p>
            <h2> {t("Expi.1")} </h2>
            <p className={styled.longText}>{t("Expi.2")}</p>
            <div className={styled.cards}>
              <div className={styled.card}>
                <h3> {t("Expi.3")} </h3>
                <p>100+</p>
              </div>
              <div className={styled.card}>
                <h3> {t("Expi.4")} </h3>
                <p>100+</p>
              </div>
              <div className={styled.card}>
                <h3> {t("Expi.5")} </h3>
                <p>100+</p>
              </div>
            </div>
          </div>
          <div className={styled.img}>
            <img src={img1} alt="photo" />
            <img src={img2} alt="photo" />
          </div>
        </div>
      </WrapperContainer>
      <Looking />
    </>
  );
}

export default Experience;
