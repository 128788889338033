import { configureStore } from "@reduxjs/toolkit";
import Request from "./form/index";
import ProductsSlice from "./products/index";
import CategorySlice from "./category/index";
import CommentsSlice from "./comments/index";
import ServicesSlice from "./SubServices/index";

export const store = configureStore({
  reducer: {
    request: Request,
    products: ProductsSlice,
    category: CategorySlice,
    comments: CommentsSlice,
    services: ServicesSlice,
  },
});
