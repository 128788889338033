import React, { useEffect } from "react";
import DetailsComp from "../../components/Details";

function Details() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <DetailsComp />
    </>
  );
}

export default Details;
