import React, { useEffect } from "react";
import ProjectComp from "../../components/Projects";

function Project() {
  useEffect(() => {
    window.scrollTo(1, 0);
  }, []);
  return (
    <>
      <ProjectComp />
    </>
  );
}

export default Project;
