import React from "react";
import Plant from "../../../assets/ForGarden/plant.png";
import styled from "./style.module.css";

function CommonText({ Img, title, Color }) {
  return (
    <>
      <div className={styled.Wrapper}>
        <p>
          <img src={Img} alt="photo" />
          <span style={{ color: `${Color}` }}>{title}</span>
        </p>
      </div>
    </>
  );
}

export default CommonText;
