import React, { useState } from "react";
import { WrapperContainer } from "../../../App-styled";
import styled from "./style.module.css";
import Logo from "../../../assets/Header/1.svg";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import HeaderMenu from "./headerHamburger";
import { MainWrapper, TopHeader } from "./style-index";
import { useEffect } from "react";

function HeaderTop() {
  const [modals, setModal] = useState(false);
  const HandelOpen = (e) => {
    setModal(true);
  };
  const HandelClose = (e) => {
    setModal(false);
  };
  const ValLang = () => {
    return window.localStorage.getItem("i18nextLng");
  };
  const HendelChange = (e) => {
    e.preventDefault();
    const LanVal = e.target.value;
    window.localStorage.setItem("i18nextLng", LanVal);
    window.location.reload();
  };
  const { t } = useTranslation();
  const [head, setHead] = useState(false);
  const [Drops, setDrops] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    Change();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  const scrollTop = window.scrollY;
  const Change = () => {
    if (scrollTop >= 60) {
      setHead(true);
      setDrops(true);
    } else {
      setHead(false);
      setDrops(false);
    }
  };
  window.addEventListener("scroll", Change);

  return (
    <>
      <MainWrapper>
        <div className={styled.WrapperBack}>
          <WrapperContainer>
            <TopHeader head={head === false}>
              <div className={styled.Wrapper}>
                <div className={styled.Ul}>
                  <div className={styled.li}>
                    <div className={styled.icon}>
                      <ion-icon name="location-outline"></ion-icon>
                    </div>
                    <div className={styled.texts}>
                      <h3>{t("Header.6")}</h3>
                    </div>
                  </div>
                  <div className={styled.li}>
                    <div className={styled.icon}>
                      <ion-icon name="mail-outline"></ion-icon>
                    </div>
                    <div className={styled.texts}>
                      <a href="mailto:info@nemagardens.com">
                        <h3>info@nemagardens.com</h3>
                      </a>
                    </div>
                  </div>
                  <div className={styled.li}>
                    <div className={styled.icon}>
                      <ion-icon name="call-outline"></ion-icon>
                    </div>
                    <div className={styled.texts}>
                      <h3>+998-78-888-88-22</h3>
                    </div>
                  </div>
                </div>
              </div>
            </TopHeader>
          </WrapperContainer>
        </div>
        <div className={styled.WrapperBack2}>
          <WrapperContainer>
            <div className={styled.Wrapper2}>
              <div className={styled.img2}>
                <NavLink to={"/"}>
                  <img src={Logo} alt="photo" />
                </NavLink>
              </div>
              <ul>
                <li>
                  <NavLink to={"/aboutus"}>{t("Header.0")}</NavLink>
                </li>
                <li>
                  <NavLink to={"/service"}>{t("Header.1")}</NavLink>
                </li>
                <li>
                  <NavLink to={"/project/4"}>{t("Header.2")}</NavLink>
                </li>
                <li>
                  <NavLink to={"/blog"}>{t("Header.3")}</NavLink>
                </li>
                <li>
                  <NavLink to={"/contact"} href="#">
                    {t("Header.4")}
                  </NavLink>
                </li>
                <li>
                  <select onChange={HendelChange}>
                    {ValLang() === "uz" ? (
                      <>
                        <option value="uz">Uzbek</option>
                        <option value="ru">Russian</option>
                        <option value="en">English</option>
                        <option value="tu">Turkey</option>
                      </>
                    ) : ValLang() === "ru" ? (
                      <>
                        <option value="ru">Russian</option>
                        <option value="uz">Uzbek</option>
                        <option value="en">English</option>
                        <option value="tu">Turkey</option>
                      </>
                    ) : ValLang() === "en" ? (
                      <>
                        <option value="en">English</option>
                        <option value="uz">Uzbek</option>
                        <option value="ru">Russian</option>
                        <option value="tu">Turkey</option>
                      </>
                    ) : ValLang() === "tu" ? (
                      <>
                        <option value="tu">Turkey</option>
                        <option value="en">English</option>
                        <option value="uz">Uzbek</option>
                        <option value="ru">Russian</option>
                      </>
                    ) : null}
                  </select>
                </li>
              </ul>
              <div className={styled.media}>
                <div className={styled.icon} onClick={HandelOpen}>
                  <ion-icon name="menu-outline"></ion-icon>
                </div>
              </div>
            </div>
          </WrapperContainer>
        </div>
      </MainWrapper>
      <HeaderMenu
        ValLang={ValLang}
        HendelChange={HendelChange}
        modal={modals}
        HandelClose={HandelClose}
      />
    </>
  );
}

export default HeaderTop;
