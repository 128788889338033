import React from "react";
import ForGarden from "./ForGarden";
import Gardening from "./Gardening";
import Services from "./Services";
import Experience from "./Expereince";
import HomeHero from "../Header/homehero";

function HomeComponent() {
  return (
    <>
      <HomeHero />
      <ForGarden />
      <Gardening />
      <Services />
      <Experience />
    </>
  );
}

export default HomeComponent;
