import React from "react";
import BackImg from "../../assets/Project/559.png";
import CommonHeader from "../common/commonHeader";
import Request from "./Request";
import MoreProducts from "./moreProducts";
import Related from "./Related";
import { useTranslation } from "react-i18next";

function MoreComp() {
  const { t } = useTranslation();
  return (
    <>
      <CommonHeader
        backImage={BackImg}
        title={t("ProjectMore.0")}
        title1={t("ProjectMore.0")}
      />
      <MoreProducts />
      <Request />
      <Related />
    </>
  );
}

export default MoreComp;
