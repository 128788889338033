import React from "react";
import BackImg from "../../assets/BlogStandart/5.png";
import CommonHeader from "../common/commonHeader";
import Cards from "./Cards";
import RequestContact from "./Request";
import { useTranslation } from "react-i18next";

function ContactComp() {
  const { t } = useTranslation();
  return (
    <>
      <CommonHeader
        title={t("Contact.0")}
        title1={t("Contact.0")}
        backImage={BackImg}
      />
      <Cards />
      <RequestContact />
    </>
  );
}

export default ContactComp;
