import React, { useEffect } from "react";
import { WrapperContainer } from "../../../App-styled";
import styled from "./style.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { GetProducts } from "../../../redux/products";

function MoreProducts() {
  const { t } = useTranslation();
  const disptach = useDispatch();
  useEffect(() => {
    disptach(GetProducts());
  }, []);
  const Data = useSelector((state) => state.products.getProducts.Data.data);
  const ProductsId = window.localStorage.getItem("ProductId");
  const Lang = window.localStorage.getItem("i18nextLng");
  return (
    <>
      <WrapperContainer>
        {Data?.map((elem) =>
          elem.id == ProductsId ? (
            <>
              <div className={styled.Wrapper}>
                <div className={styled.images}>
                  <img className={styled.mainImg} src={elem.img1} alt="photo" />
                  <div className={styled.miniImgs}>
                    <img src={elem.img1} alt="photo" />
                    <img src={elem.img2} alt="photo" />
                    <img src={elem.img3} alt="photo" />
                  </div>
                </div>
                <div className={styled.texts}>
                  <h2>
                    {Lang == "uz"
                      ? elem.title_uz
                      : Lang == "ru"
                      ? elem.title_ru
                      : Lang == "en"
                      ? elem.title_uz
                      : Lang == "tu"
                      ? elem.title_tr
                      : null}
                  </h2>
                  <p className={styled.subtext}>
                    {Lang == "uz"
                      ? elem.description_uz
                      : Lang == "ru"
                      ? elem.description_ru
                      : Lang == "en"
                      ? elem.description_en
                      : Lang == "tu"
                      ? elem.description_tr
                      : null}
                  </p>
                  <ul>
                    <li>
                      <h3>
                        {t("ProjectMore.1")} :{" "}
                        {Lang == "uz"
                          ? elem.category.category_name_uz
                          : Lang == "ru"
                          ? elem.category.category_name_ru
                          : Lang == "en"
                          ? elem.category.category_name_en
                          : Lang == "tu"
                          ? elem.category.category_name_tr
                          : null}
                      </h3>
                    </li>
                  </ul>
                  <div className={styled.connecting}>
                    <h3>{t("ProjectMore.2")}</h3>
                    <div className={styled.icon}>
                      <a href="#">
                        <i class="bx bxl-facebook"></i>
                      </a>
                      <a href="#">
                        <i class="bx bxl-twitter"></i>
                      </a>
                      <a href="https://instagram.com/nemagardens_uz?igshid=MzRlODBiNWFlZA==">
                        <i class="bx bxl-instagram"></i>
                      </a>
                      <a href="https://t.me/nemagardens_uz">
                        <i class="bx bxl-telegram"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null
        )}
      </WrapperContainer>
    </>
  );
}

export default MoreProducts;
