import React, { useEffect } from "react";
import { WrapperContainer } from "../../../App-styled";
import styled from "./style.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { GetComments } from "../../../redux/comments";
import { GetCategory } from "../../../redux/category";
import { NavLink } from "react-router-dom";

function MoreComment() {
  const { t } = useTranslation();
  const disptach = useDispatch();
  useEffect(() => {
    disptach(GetComments());
    disptach(GetCategory());
  }, []);
  const Data = useSelector((state) => state.comments.getComments.Data.data);
  const DataCategory = useSelector(
    (state) => state.category.getCategory.Data.data
  );
  const BlogId = window.localStorage.getItem("BlogId");
  const lang = window.localStorage.getItem("i18nextLng");
  return (
    <>
      <WrapperContainer>
        <div className={styled.Wrapper}>
          {Data?.map((elem) =>
            elem.id == BlogId ? (
              <>
                <div className={styled.moreComment}>
                  <h2>
                    {lang == "ru"
                      ? elem.title_ru
                      : lang == "uz"
                      ? elem.title_uz
                      : lang == "en"
                      ? elem.title_en
                      : lang == "tu"
                      ? elem.title_tr
                      : null}
                  </h2>
                  <p className={styled.subtext}>
                    {lang == "ru"
                      ? elem.description_ru
                      : lang == "uz"
                      ? elem.description_uz
                      : lang == "en"
                      ? elem.description_en
                      : lang == "tu"
                      ? elem.description_tr
                      : null}
                  </p>
                  <div className={styled.img}>
                    <img
                      className={styled.mainImg}
                      src={elem.image}
                      alt="photo"
                    />
                  </div>
                  <p className={styled.subtext}>
                    {lang == "ru"
                      ? elem.title2_ru
                      : lang == "uz"
                      ? elem.title2_uz
                      : lang == "en"
                      ? elem.title2_en
                      : lang == "tu"
                      ? elem.title2_tr
                      : null}
                  </p>
                </div>
              </>
            ) : null
          )}
          <div className={styled.pluss}>
            <div className={styled.categories}>
              <h2>{t("Blog.1")}</h2>
              <ul>
                {DataCategory?.slice(0, 5).map((elem) => (
                  <li>
                    <NavLink to={"/project/4"}>
                      <divq>
                        <h3>
                          {lang == "ru"
                            ? elem.category_name_ru
                            : lang == "uz"
                            ? elem.category_name_uz
                            : lang == "en"
                            ? elem.category_name_en
                            : lang == "tu"
                            ? elem.category_name_tr
                            : null}
                        </h3>
                        <i class="bx bx-chevrons-right"></i>
                      </divq>
                      <div></div>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
            <div className={styled.contactUs}>
              <h2>{t("Blog.2")}</h2>
              <p>{t("Blog.3")}</p>
              <NavLink to={"/contact"}>
                <button>{t("Blog.4")}</button>
              </NavLink>
            </div>
          </div>
        </div>
      </WrapperContainer>
    </>
  );
}

export default MoreComment;
