import React, { useRef, useState } from "react";
import { WrapperContainer } from "../../../App-styled";
import CommonText from "../../common/commonText";
import Plant from "../../../assets/plant 1(3).png";
import styled from "./style.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RequestPost } from "../../../redux/form";
import SuccessModal from "./successModal";

function RequestContact() {
  const { t } = useTranslation();
  const name = useRef();
  const number = useRef();
  const decription = useRef();
  const diptach = useDispatch();
  const [modal, setModal] = useState(false);
  const HandelOpen = () => setModal(true);
  const HandelClose = () => setModal(false);
  const Success = useSelector((state) => state.request.postRequest.Success);
  const HandelChange = async (e) => {
    e.preventDefault();
    const body = {
      name: name.current.value,
      phone_number: number.current.value,
      comentary: decription.current.value,
    };
    await diptach(RequestPost(body));
    setModal(true);
  };

  return (
    <>
      <WrapperContainer>
        <div className={styled.Wrapper}>
          <div className={styled.map}>
            <CommonText title={t("Contact.0")} Color="#2A7D2E" Img={Plant} />
            <h2>{t("Contact.4")}</h2>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d391.7839282203937!2d69.24737045913301!3d41.35052389185901!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sru!2s!4v1685009921962!5m2!1sru!2s"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className={styled.form}>
            <form onSubmit={HandelChange}>
              <input
                ref={name}
                placeholder={t("Contact.5")}
                type="text"
                required
              />
              <input
                ref={number}
                placeholder={t("Contact.6")}
                type="number"
                required
              />
              <textarea
                ref={decription}
                placeholder={t("Contact.7")}
                cols="30"
                rows="0"
                required
              ></textarea>
              <button type="submit">{t("Contact.8")}</button>
            </form>
          </div>
        </div>
      </WrapperContainer>
      <SuccessModal
        handleClose={HandelClose}
        handleOpen={HandelOpen}
        open={modal}
      />
    </>
  );
}

export default RequestContact;
