import React from "react";
import Plant from "../../../assets/ForGarden/plant.png";
import { WrapperContainer } from "../../../App-styled";
import styled from "./style.module.css";
import CardGarden from "./cards";
import { useTranslation } from "react-i18next";

function ForGarden() {
  const { t } = useTranslation();
  return (
    <>
      <div className={styled.WrapperBack}>
        <WrapperContainer>
          <div className={styled.Wrapper}>
            <div className={styled.texts}>
              <p>
                <img src={Plant} alt="photo" />
                <span>{t("ForGarden.0")}</span>
              </p>
              <h2>{t("ForGarden.1")}</h2>
            </div>
            <div className={styled.more}>
              <p>{t("ForGarden.2")}</p>
            </div>
          </div>
        </WrapperContainer>
        <CardGarden />
      </div>
    </>
  );
}

export default ForGarden;
