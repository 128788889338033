import React from "react";
import { WrapperContainer } from "../../App-styled";
import styled from "./style.module.css";
import Logo from "../../assets/Header/1.svg";
import FooterLogos from "./FooterLogos";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

function Footer() {
  const { t } = useTranslation();
  return (
    <>
      <FooterLogos />
      <div className={styled.WrapperBack}>
        <WrapperContainer>
          <div className={styled.Wrapper}>
            <ul>
              <li>
                <div className={styled.img}>
                  <img className={styled.logo} src={Logo} alt="photo" />
                </div>
                <p className={styled.subtext}>{t("Footer.6")}</p>
                <div className={styled.icons}>
                  <a href="#">
                    <div className={styled.icon}>
                      <i class="bx bxl-facebook"></i>
                    </div>
                  </a>
                  <a href="https://t.me/nemagardens_uz">
                    <div className={styled.icon}>
                      <i class="bx bxl-telegram"></i>
                    </div>
                  </a>
                  <a href="#">
                    <div className={styled.icon}>
                      <i class="bx bxl-twitter"></i>
                    </div>
                  </a>
                  <a href="https://instagram.com/nemagardens_uz?igshid=MzRlODBiNWFlZA==">
                    <div className={styled.icon}>
                      <i class="bx bxl-instagram"></i>
                    </div>
                  </a>
                </div>
              </li>
              <li>
                <h3>{t("Footer.1")}</h3>
                <NavLink to={"/aboutus"}>
                  <p>{t("Footer.2")}</p>
                </NavLink>
                <NavLink to={"/service"}>
                  <p>{t("Footer.3")}</p>
                </NavLink>
                <NavLink to={"/project/4"}>
                  <p>{t("Footer.4")}</p>
                </NavLink>
              </li>
              <li>
                <h3>{t("Footer.0")}</h3>
                <p>{t("Header.6")}</p>
                <a href="mailto:info@nemagardens.uz">
                  <p>info@nemagardens.uz</p>
                </a>
                <p>+998-78-888-88-22</p>
                <p className={styled.headNumbers}>{t("Footer.7")} :</p>
                <p className={styled.numbers}>+998-97-912-08-05</p>
              </li>
            </ul>
          </div>
        </WrapperContainer>
      </div>
      <div className={styled.WrapperBack2}>
        <WrapperContainer>
          <div className={styled.Bottom}>
            <div className={styled.icona}>
              <a href="#">
                <ion-icon name="arrow-up-outline"></ion-icon>
              </a>
            </div>
            <p>{t("Footer.8")}</p>
          </div>
        </WrapperContainer>
      </div>
    </>
  );
}

export default Footer;
