import React, { useRef, useState } from "react";
import { WrapperContainer } from "../../../App-styled";
import styled from "./style.module.css";
import { useTranslation } from "react-i18next";
import { RequestPost } from "../../../redux/form";
import { useDispatch } from "react-redux";
import SuccessModal from "./successModal";

function Request() {
  const { t } = useTranslation();
  const name = useRef();
  const number = useRef();
  const decription = useRef();
  const diptach = useDispatch();
  const [modal, setModal] = useState(false);
  const HandelOpen = () => setModal(true);
  const HandelClose = () => setModal(false);
  const HandelChange = async (e) => {
    e.preventDefault();
    const body = {
      name: name.current.value,
      phone_number: number.current.value,
      comentary: decription.current.value,
    };
    await diptach(RequestPost(body));
    setModal(true);
  };
  return (
    <>
      <WrapperContainer>
        <div className={styled.Wrapper}>
          <h3>{t("Contact.9")}</h3>
          <form onSubmit={HandelChange}>
            <div>
              <input
                ref={name}
                placeholder={t("Contact.5")}
                type="text"
                required
              />
              <input
                ref={number}
                placeholder={t("Contact.6")}
                type="number"
                required
              />
            </div>
            <textarea
              ref={decription}
              placeholder={t("Contact.7")}
              cols="30"
              rows="10"
              required
            ></textarea>
            <button type="submit">{t("Contact.8")}</button>
          </form>
        </div>
      </WrapperContainer>
      <SuccessModal
        handleClose={HandelClose}
        handleOpen={HandelOpen}
        open={modal}
      />
    </>
  );
}

export default Request;
