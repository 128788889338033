import React from "react";
import { WrapperContainer } from "../../../App-styled";
import Img1 from "../../../assets/Contact/Icon(4).png";
import Img2 from "../../../assets/Contact/Icon(5).png";
import Img3 from "../../../assets/Contact/Icon(6).png";
import styled from "./style.module.css";
import { useTranslation } from "react-i18next";

function Cards() {
  const { t } = useTranslation();
  return (
    <>
      <WrapperContainer>
        <div className={styled.Wrappe}>
          <div className={styled.Wrapper}>
            <div className={styled.card}>
              <img src={Img1} alt="photo" />
              <p>{t("Contact.1")}</p>
              <h3>{t("Header.6")}</h3>
            </div>
            <div className={styled.card}>
              <img src={Img2} alt="photo" />
              <p>{t("Contact.2")}</p>
              <a href="mailto:info@nemagardens.com">
                <p>info@nemagardens.com</p>
              </a>
            </div>
            <div className={styled.card}>
              <img src={Img3} alt="photo" />
              <p>{t("Contact.3")}</p>
              <h3>+998-78-888-88-22</h3>
            </div>
          </div>
        </div>
      </WrapperContainer>
    </>
  );
}

export default Cards;
