import React from "react";
import { WrapperContainer } from "../../../App-styled";
import Plant from "../../../assets/plant 1(3).png";
import CommonText from "../../common/commonText";
import Img1 from "../../../assets/Service/Group(4).png";
import Img2 from "../../../assets/Service/landscape 2.png";
import Img3 from "../../../assets/Service/lawnmower 1.png";
import Img4 from "../../../assets/Service/spider-plant 1.png";
import Img5 from "../../../assets/Service/lox.png";
import styled from "./style.module.css";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

function WhatDo() {
  const { t } = useTranslation();
  return (
    <>
      <WrapperContainer>
        <div className={styled.Wrapper}>
          <div className={styled.tetxs}>
            <CommonText title={t("Servies.0")} Color="#2A7D2E" Img={Plant} />
            <h2> {t("Servies.1")}</h2>
            {/* <NavLink to={"/details"}> */}
            <div className={styled.cards}>
              <div className={styled.left}>
                <div className={styled.card}>
                  <div className={styled.icon}>
                    <img src={Img1} alt="photo" />
                  </div>
                  <h3> {t("Servies.2")}</h3>
                  {/* <p>Sed perspiciatis unde omnis volunteer accusantium</p> */}
                </div>
                <div className={styled.card}>
                  <div className={styled.icon}>
                    <img src={Img2} alt="photo" />
                  </div>
                  <h3>{t("Servies.3")}</h3>
                  {/* <p>Sed perspiciatis unde omnis volunteer accusantium</p> */}
                </div>
              </div>

              <div className={styled.right}>
                <div className={styled.card}>
                  <div className={styled.icon}>
                    <img src={Img3} alt="photo" />
                  </div>
                  <h3>{t("Servies.4")}</h3>
                  {/* <p>Sed perspiciatis unde omnis volunteer accusantium</p> */}
                </div>
                <div className={styled.card}>
                  <div className={styled.icon}>
                    <img src={Img4} alt="photo" />
                  </div>
                  <h3>{t("Servies.5")}</h3>
                  {/* <p>Sed perspiciatis unde omnis volunteer accusantium</p> */}
                </div>
              </div>
            </div>
            {/* </NavLink> */}
          </div>
          <div className={styled.imgDiv}>
            <img src={Img5} alt="photo" />
          </div>
        </div>
      </WrapperContainer>
    </>
  );
}

export default WhatDo;
