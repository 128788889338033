import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils";
export const GetComments = createAsyncThunk("Comments/get", async () => {
  return axios.get(`${API_URL}/services`).then((res) => res);
});
const CommentsSlice = createSlice({
  name: "Comments",
  initialState: {
    getComments: {
      Error: false,
      Loading: false,
      Success: false,
      Data: [],
    },
  },
  extraReducers: {
    [GetComments.pending]: (state, action) => {
      state.getComments.Loading = true;
    },
    [GetComments.fulfilled]: (state, action) => {
      state.getComments.Error = false;
      state.getComments.Success = true;
      state.getComments.Loading = false;
      state.getComments.Data = action.payload;
    },
    [GetComments.rejected]: (state, action) => {
      state.getComments.Error = true;
      state.getComments.Success = false;
      state.getComments.Loading = false;
      state.getComments.Data = [];
    },
  },
});

export default CommentsSlice.reducer;
