import { styled } from "styled-components";

export const Card = styled.div`
  height: 350px;
  width: 85%;
  margin: 5% auto 0;
  border-radius: 20px;
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: 125%;
  background-position: center top;
  overflow: hidden;
  cursor: pointer;
  transition: 0.65s;
`;
