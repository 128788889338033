import React, { useEffect, useState } from "react";
import { WrapperContainer } from "../../../App-styled";
import CommonText from "../../common/commonText";
import Img1 from "../../../assets/plant 1(3).png";
import { Tabs } from "antd";
import "./tab.css";
import { Col, Row } from "react-grid-system";
import styled from "./style.module.css";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { GetProducts } from "../../../redux/products";
import { GetCategory } from "../../../redux/category";
import { Card } from "./styled-index";

function Products() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetProducts());
  }, []);
  useEffect(() => {
    dispatch(GetCategory());
  }, []);
  const lang = window.localStorage.getItem("i18nextLng");
  const [category, setCategory] = useState(1);
  const Datas = useSelector((state) => state.category.getCategory.Data);
  const navigate = useNavigate();
  const items = Datas?.data?.map((elem, index) => {
    const id = elem.id;
    return {
      label: `${
        lang == "ru"
          ? elem.category_name_ru
          : lang == "uz"
          ? elem.category_name_uz
          : lang == "en"
          ? elem.category_name_en
          : lang == "tu"
          ? elem.category_name_tr
          : null
      }`,
      key: `${id}`,
      children: `${elem.id}`,
    };
  });
  const Data = useSelector((state) => state.products.getProducts.Data.data);
  const { t } = useTranslation();
  const HandelChange = (e) => {
    navigate(`/project/${e}`);
    setCategory(1);
  };

  const { id } = useParams();
  console.log(id);

  return (
    <>
      <WrapperContainer>
        <div className={styled.Wrapper}>
          <CommonText Color="#2A7D2E" title={t("Projects.2")} Img={Img1} />
          <h2>{t("Projects.1")}</h2>
          <Tabs
            onChange={HandelChange}
            items={Datas?.data?.map((elem, i) => {
              const id = String(elem.id);
              return {
                label:
                  lang == "ru"
                    ? elem.category_name_ru
                    : lang == "uz"
                    ? elem.category_name_uz
                    : lang == "en"
                    ? elem.category_name_en
                    : lang == "tu"
                    ? elem.category_name_tr
                    : null,
                key: id,
                children: id,
              };
            })}
            activeKey={id}
          />
          <Row style={{ margin: 0, padding: 0 }}>
            {Data?.map((elem) =>
              category == 0 ? (
                <>
                  <Col
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={4}
                    xxl={4}
                    onClick={() =>
                      window.localStorage.setItem("ProductId", elem.id)
                    }
                  >
                    <NavLink to={"/more"}>
                      <Card img={elem?.img1} className={styled.card}>
                        <div className={styled.bottom}>
                          <div>
                            <h3>{t("Projects.4")}</h3>
                            <p>{t("Projects.5")}</p>
                          </div>
                          <div className={styled.icon}>
                            <i class="bx bx-trending-up"></i>
                          </div>
                        </div>
                      </Card>
                    </NavLink>
                  </Col>
                </>
              ) : (
                <>
                  {id == elem.category.id ? (
                    <>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={4}
                        xxl={4}
                        onClick={() =>
                          window.localStorage.setItem("ProductId", elem.id)
                        }
                      >
                        <NavLink to={"/more"}>
                          <Card img={elem.img1} className={styled.card}>
                            <div className={styled.bottom}>
                              <div>
                                <h3>{t("Projects.4")}</h3>
                                <p>{t("Projects.5")}</p>
                              </div>
                              <div className={styled.icon}>
                                <i class="bx bx-trending-up"></i>
                              </div>
                            </div>
                          </Card>
                        </NavLink>
                      </Col>
                    </>
                  ) : null}
                </>
              )
            )}
          </Row>
        </div>
      </WrapperContainer>
    </>
  );
}

export default Products;
