import React from "react";
import HeaderTop from "./HeaderTop";

function Header() {
  return (
    <>
      <HeaderTop />
    </>
  );
}

export default Header;
