import React from "react";
import { WrapperContainer } from "../../../App-styled";
import CommonText from "../../common/commonText";
import Plant from "../../../assets/ForGarden/plant.png";
import img1 from "../../../assets/Gradining/Group(1).png";
import img2 from "../../../assets/Gradining/Group(2).png";
import img3 from "../../../assets/Gradining/target 1.png";
import styled from "./style.module.css";
import { useTranslation } from "react-i18next";

function Gardening() {
  const { t } = useTranslation();
  return (
    <>
      <WrapperContainer>
        <div className={styled.Wrapper}>
          <div className={styled.texts}>
            <CommonText title={t("AboutUs.1")} Img={Plant} Color="#2A7D2E" />
            <h2>{t("AboutUs.2")}</h2>
          </div>
          <div className={styled.infors}>
            <p className={styled.subtext}>{t("AboutUs.3")}</p>
          </div>
        </div>
        <div className={styled.cards}>
          <div className={styled.card}>
            <div className={styled.icon}>
              <img src={img1} alt="photo" />
            </div>
            <p>{t("AboutUs.4")}</p>
          </div>
          <div className={styled.card}>
            <div className={styled.icon}>
              <img src={img2} alt="photo" />
            </div>
            <p>{t("AboutUs.5")}</p>
          </div>
          <div className={styled.card}>
            <div className={styled.icon}>
              <img src={img3} alt="photo" />
            </div>

            <p>{t("AboutUs.6")}</p>
          </div>
        </div>
      </WrapperContainer>
    </>
  );
}

export default Gardening;
