import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils";
export const GetServices = createAsyncThunk("Services/get", async () => {
  return axios.get(`${API_URL}/sub_services`).then((res) => res);
});
const ServicesSlice = createSlice({
  name: "Services",
  initialState: {
    getServices: {
      Error: false,
      Loading: false,
      Success: false,
      Data: [],
    },
  },
  extraReducers: {
    [GetServices.pending]: (state, action) => {
      state.getServices.Loading = true;
    },
    [GetServices.fulfilled]: (state, action) => {
      state.getServices.Error = false;
      state.getServices.Success = true;
      state.getServices.Loading = false;
      state.getServices.Data = action.payload;
    },
    [GetServices.rejected]: (state, action) => {
      state.getServices.Error = true;
      state.getServices.Success = false;
      state.getServices.Loading = false;
      state.getServices.Data = [];
    },
  },
});

export default ServicesSlice.reducer;
