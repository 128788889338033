import React from "react";
import { WrapperContainer } from "../../../App-styled";
import styled from "./style.module.css";
import CommonText from "../../common/commonText";
import Plant from "../../../assets/plant 1(3).png";
import Img1 from "../../../assets/Modern/Icon(1).png";
import Img2 from "../../../assets/Modern/Icon(2).png";
import MainImg from "../../../assets/nme.png";
import { useTranslation } from "react-i18next";

function Modern() {
  const { t } = useTranslation();
  return (
    <>
      <WrapperContainer>
        <div className={styled.Wrapper}>
          <div className={styled.imgDiv}>
            <img src={MainImg} alt="photo" />
          </div>
          <div className={styled.texts}>
            <CommonText title={t("Modern.0")} Color="2A7D2E" Img={Plant} />
            <h2>{t("Modern.1")} </h2>
            <p className={styled.subtext}>{t("Modern.2")}</p>
            <div className={styled.card}>
              <img src={Img1} alt="photo" />
              <h3>{t("Modern.3")} </h3>
              <p>{t("Modern.4")}</p>
            </div>
            <div className={styled.card}>
              <img src={Img2} alt="photo" />
              <h3>{t("Modern.5")} </h3>
              <p>{t("Modern.6")}</p>
            </div>
          </div>
        </div>
      </WrapperContainer>
    </>
  );
}

export default Modern;
