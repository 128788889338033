import React from "react";
import { WrapperContainer } from "../../../App-styled";
import styled from "./style.module.css";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
function HomeHero() {
  const { t } = useTranslation();
  return (
    <>
      <div className={styled.Wrapper2}></div>
      <div className={styled.WrapperBack}>
        <WrapperContainer>
          <div className={styled.Wrapper}>
            <div className={styled.subtext}>
              <p>{t("HomeHero.0")}</p>
            </div>
            <h2>{t("HomeHero.1")}</h2>
            <p className={styled.subtextP}>{t("HomeHero.2")}</p>
            <div className={styled.Buttons}>
              <NavLink to={"/contact"}>
                <button>{t("HomeHero.3")}</button>
              </NavLink>
            </div>
          </div>
        </WrapperContainer>
      </div>
    </>
  );
}

export default HomeHero;
