import React from "react";
import CommonHeader from "../common/commonHeader";
import BackImg from "../../assets/Project/559.png";
import Img1 from "../../assets/plant 1(3).png";
import Products from "./Products";
import { useTranslation } from "react-i18next";

function ProjectComp() {
  const { t } = useTranslation();
  return (
    <>
      <CommonHeader
        backImage={BackImg}
        title={t("Projects.0")}
        title1={t("Projects.3")}
      />
      <Products />
    </>
  );
}

export default ProjectComp;
