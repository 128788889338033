import React from "react";
import styled from "./style.module.css";
import img from "../../../../assets/Expi/Icon.png";
import { WrapperContainer } from "../../../../App-styled";
import { Col, Row } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetProducts } from "../../../../redux/products";
import { Card } from "./style-index";
import { NavLink } from "react-router-dom";

function Looking() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetProducts());
  }, []);
  const Data = useSelector((state) => state.products.getProducts.Data.data);
  const { t } = useTranslation();
  const lang = window.localStorage.getItem("i18nextLng");
  return (
    <>
      <WrapperContainer>
        <div className={styled.Wrapper}>
          <h2>
            <img src={img} alt="photo" />
            <span> {t("Expi.6")} </span>
          </h2>
        </div>
        <div className={styled.cards}>
          <Row style={{ margin: 0, padding: 0 }}>
            {Data?.slice(0, 8).map((elem) => (
              <Col xl={3} xxl={3} xs={12} md={6} lg={6} sm={6}>
                <NavLink
                  to={"/more"}
                  onClick={() =>
                    window.localStorage.setItem("ProductId", elem.id)
                  }
                >
                  <Card img={elem.img1}>
                    <div className={styled.card}>
                      <div className={styled.icon}>
                        <ion-icon name="trending-up-outline"></ion-icon>
                      </div>
                      <div className={styled.text}>
                        <h3>
                          {lang == "ru"
                            ? elem.title_ru
                            : lang == "uz"
                            ? elem.title_uz
                            : lang == "en"
                            ? elem.title_en
                            : lang == "tu"
                            ? elem.title_tr
                            : null}
                        </h3>
                        <p>
                          {lang == "ru"
                            ? elem.description_ru
                            : lang == "uz"
                            ? elem.description_uz
                            : lang == "en"
                            ? elem.description_en
                            : lang == "tu"
                            ? elem.description_tr
                            : null}
                        </p>
                      </div>
                    </div>
                  </Card>
                </NavLink>
              </Col>
            ))}
          </Row>
        </div>
      </WrapperContainer>
    </>
  );
}

export default Looking;
