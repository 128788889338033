import React from "react";
import CommonHeader from "../common/commonHeader";
import BackImg from "../../assets/Service/2564.png";
import WhatDo from "./WhatDo";
import Servicess from "./Services";
import { useTranslation } from "react-i18next";

function ServiceComp() {
  const { t } = useTranslation();
  return (
    <>
      <CommonHeader
        backImage={BackImg}
        title={t("Servies.0")}
        title1={t("Servies.0")}
      />
      <WhatDo />
      <Servicess />
    </>
  );
}

export default ServiceComp;
