import React from "react";
import { WrapperContainer } from "../../../App-styled";
import CommonText from "../../common/commonText";
import Plant from "../../../assets/plant 1(3).png";
import Slider from "react-slick";
import styled from "./style.module.css";
import Img from "../../../assets/Global/Group(3).png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
import { useTranslation } from "react-i18next";

function Global() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };
  const Data = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const { t } = useTranslation();
  return (
    <>
      <WrapperContainer>
        <div className={styled.Wrapper}>
          <CommonText Img={Plant} title={t("Comments.11")} Color="#2A7D2E" />
          <h2> {t("Comments.0")} </h2>
        </div>
        <div className="slider">
          <Slider {...settings}>
            <div>
              <div className={styled.card}>
                <div className={styled.headerCard}>
                  <img src={Img} alt="photo" />
                  <div className={styled.texts}>
                    <h3> {t("Comments.1")} </h3>
                    <div className={styled.icons}>
                      <i class="bx bxs-star"></i>
                      <i class="bx bxs-star"></i>
                      <i class="bx bxs-star"></i>
                      <i class="bx bxs-star"></i>
                      <i class="bx bxs-star"></i>
                    </div>
                  </div>
                </div>
                <p className={styled.subtext}>{t("Comments.2")}</p>
              </div>
            </div>
            <div>
              <div className={styled.card}>
                <div className={styled.headerCard}>
                  <img src={Img} alt="photo" />
                  <div className={styled.texts}>
                    <h3> {t("Comments.3")} </h3>
                    <div className={styled.icons}>
                      <i class="bx bxs-star"></i>
                      <i class="bx bxs-star"></i>
                      <i class="bx bxs-star"></i>
                      <i class="bx bxs-star"></i>
                      <i class="bx bxs-star"></i>
                    </div>
                  </div>
                </div>
                <p className={styled.subtext}>{t("Comments.4")}</p>
              </div>
            </div>
            <div>
              <div className={styled.card}>
                <div className={styled.headerCard}>
                  <img src={Img} alt="photo" />
                  <div className={styled.texts}>
                    <h3> {t("Comments.5")} </h3>
                    <div className={styled.icons}>
                      <i class="bx bxs-star"></i>
                      <i class="bx bxs-star"></i>
                      <i class="bx bxs-star"></i>
                      <i class="bx bxs-star"></i>
                      <i class="bx bxs-star"></i>
                    </div>
                  </div>
                </div>
                <p className={styled.subtext}>{t("Comments.6")}</p>
              </div>
            </div>
            <div>
              <div className={styled.card}>
                <div className={styled.headerCard}>
                  <img src={Img} alt="photo" />
                  <div className={styled.texts}>
                    <h3> {t("Comments.7")} </h3>
                    <div className={styled.icons}>
                      <i class="bx bxs-star"></i>
                      <i class="bx bxs-star"></i>
                      <i class="bx bxs-star"></i>
                      <i class="bx bxs-star"></i>
                      <i class="bx bxs-star"></i>
                    </div>
                  </div>
                </div>
                <p className={styled.subtext}>{t("Comments.8")}</p>
              </div>
            </div>
            <div>
              <div className={styled.card}>
                <div className={styled.headerCard}>
                  <img src={Img} alt="photo" />
                  <div className={styled.texts}>
                    <h3> {t("Comments.9")} </h3>
                    <div className={styled.icons}>
                      <i class="bx bxs-star"></i>
                      <i class="bx bxs-star"></i>
                      <i class="bx bxs-star"></i>
                      <i class="bx bxs-star"></i>
                      <i class="bx bxs-star"></i>
                    </div>
                  </div>
                </div>
                <p className={styled.subtext}>{t("Comments.10")}</p>
              </div>
            </div>
          </Slider>
        </div>
      </WrapperContainer>
    </>
  );
}

export default Global;
