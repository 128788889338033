import React from "react";
import { WrapperContainer } from "../../../App-styled";
import styled from "./style.module.css";
import { useTranslation } from "react-i18next";

function BlogRequest() {
  const { t } = useTranslation();
  return (
    <>
      <WrapperContainer>
        <div className={styled.Wrapper}>
          <h3>{t("Contact.9")}</h3>
          <form>
            <div>
              <input placeholder={t("Contact.5")} type="text" />
              <input placeholder={t("Contact.6")} type="email" />
            </div>
            <textarea
              placeholder={t("Contact.7")}
              cols="30"
              rows="10"
            ></textarea>
            <button>{t("Contact.8")}</button>
          </form>
        </div>
      </WrapperContainer>
    </>
  );
}

export default BlogRequest;
