import React, { useEffect } from "react";
import { WrapperContainer } from "../../../App-styled";
import { Col, Row } from "react-grid-system";
import styled from "./style.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { GetProducts } from "../../../redux/products";

function Related() {
  const dispatach = useDispatch();
  useEffect(() => {
    dispatach(GetProducts());
  }, []);
  const data = useSelector((state) => state.products.getProducts.Data.data);
  const Lang = window.localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  return (
    <>
      <WrapperContainer>
        <div className={styled.Wrapper}>
          <h2>{t("ProjectMore.3")}</h2>
          <Row style={{ margin: 0, padding: 0 }}>
            {data?.slice(0, 6).map((elem) => (
              <Col
                xl={4}
                lg={12}
                onClick={() => {
                  window.localStorage.setItem("ProductId", elem.id);
                  window.location.reload();
                }}
                className={styled.col}
              >
                <div className={styled.card}>
                  <img src={elem.img1} alt="photo" />
                  <div className={styled.text}>
                    <h3>
                      {Lang == "uz"
                        ? elem.title_uz
                        : Lang == "ru"
                        ? elem.title_ru
                        : Lang == "en"
                        ? elem.title_uz
                        : Lang == "tu"
                        ? elem.title_tr
                        : null}
                    </h3>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </WrapperContainer>
    </>
  );
}

export default Related;
