import React from "react";
import CommonHeader from "../common/commonHeader";
import BacjImg from "../../assets/BlogStandart/110.png";
import Comments from "./comments";
import { useTranslation } from "react-i18next";
function BlogComp() {
  const { t } = useTranslation();
  return (
    <>
      <CommonHeader
        title={t("Blog.0")}
        title1={t("Blog.0")}
        backImage={BacjImg}
      />
      <Comments />
    </>
  );
}

export default BlogComp;
