import React from "react";
import styled from "./style.module.css";
import { WrapperContainer } from "../../../../App-styled";
import img1 from "../../../../assets/ForGarden/1.svg";
import img2 from "../../../../assets/ForGarden/2.svg";
import img3 from "../../../../assets/ForGarden/3.svg";
import img4 from "../../../../assets/ForGarden/4.svg";
import i1mg from "../../../../assets/ForGarden/1.png";
import i2mg from "../../../../assets/ForGarden/2.png";
import i3mg from "../../../../assets/ForGarden/3.png";
import i4mg from "../../../../assets/ForGarden/4.png";
import i5md from "../../../../assets/ForGarden/5.png";
import { useTranslation } from "react-i18next";

function CardGarden() {
  const { t } = useTranslation();
  return (
    <>
      <WrapperContainer>
        <div className={styled.Wrapper}>
          <div className={styled.cards}>
            <div className={styled.card}>
              <img className={styled.mainImg} src={i1mg} alt="photo" />
              <div className={styled.texts}>
                <img className={styled.IconImg} src={img1} alt="photo" />
                <h3>{t("ForGarden.3")}</h3>
              </div>
            </div>
            <div className={styled.card}>
              <img className={styled.mainImg} src={i2mg} alt="photo" />
              <div className={styled.texts}>
                <img className={styled.IconImg} src={img4} alt="photo" />
                <h3>{t("ForGarden.4")}</h3>
              </div>
            </div>
            <div className={styled.card}>
              <img className={styled.mainImg} src={i3mg} alt="photo" />
              <div className={styled.texts}>
                <img className={styled.IconImg} src={img2} alt="photo" />
                <h3>{t("ForGarden.5")}</h3>
              </div>
            </div>
            <div className={styled.card}>
              <img className={styled.mainImg} src={i4mg} alt="photo" />
              <div className={styled.texts}>
                <img className={styled.IconImg} src={img3} alt="photo" />
                <h3>{t("ForGarden.6")}</h3>
              </div>
            </div>
            <div className={styled.card}>
              <img className={styled.mainImg} src={i5md} alt="photo" />
              <div className={styled.texts}>
                <img className={styled.IconImg} src={img4} alt="photo" />
                <h3>{t("ForGarden.7")}</h3>
              </div>
            </div>
          </div>
        </div>
      </WrapperContainer>
    </>
  );
}

export default CardGarden;
