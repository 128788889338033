import React, { useEffect } from "react";
import BlogDetailsComp from "../../components/BlogDetails";

function BlogDetails() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <BlogDetailsComp />
    </>
  );
}

export default BlogDetails;
