import styled from "styled-components";

export const Menu = styled.div`
  display: none;
  background-color: #f8f8f8;
  position: fixed;
  height: 100% !important;
  /* height: ${(props) => (props.modal ? "0px" : "100%")}; */
  padding: 18px 20px 0 20px;
  width: 90%;
  z-index: 1000;
  top: 0;
  right: ${(props) => (props.modal ? "-1800px" : "0%")};
  transition: 0.8s;
  overflow-y: scroll;
  @media screen and (max-width: 1210px) {
    display: block;
  }

  ul {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    a {
      text-decoration: none;
      color: black;
      font-size: 20px;
      font-weight: 600;
    }
    select {
      background-color: transparent;
      border: none;
      color: white;
      font-size: 17px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      color: black;
    }
    option {
      font: 500 17px tahoma;
    }
  }
  .close {
    display: flex;
    align-items: flex-end;
    justify-content: end;
    cursor: pointer;
    .bx {
      font-size: 30px;
      transition: all 0.55s;
      &:hover {
        transform: rotate(180deg);
      }
    }
  }
`;
