import React from "react";
import { WrapperContainer } from "../../../App-styled";
import Plant from "../../../assets/Services/5.png";
import styled from "./style.module.css";
import img1 from "../../../assets/Services/1.png";
import img2 from "../../../assets/Services/2.png";
import img3 from "../../../assets/Services/3.png";
import img4 from "../../../assets/Services/4.png";
import img5 from "../../../assets/Services/6.png";
import img6 from "../../../assets/Services/7.png";
import i1mg from "../../../assets/Ser/1.png";
import i2mg from "../../../assets/Ser/2.png";
import i3mg from "../../../assets/Ser/3.png";
import i4mg from "../../../assets/Ser/4.png";
import i5mg from "../../../assets/Ser/5.png";
import i6mg from "../../../assets/Ser/6.png";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetComments } from "../../../redux/comments";

function Services() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetComments());
  }, []);
  const Data = useSelector((state) => state.comments.getComments.Data.data);
  const Lang = window.localStorage.getItem("i18nextLng");

  return (
    <>
      <div className={styled.WrapperBack}>
        <WrapperContainer>
          <div className={styled.Wrapper}>
            <p>
              <img src={Plant} alt="photo" />
              <span>{t("Servies.0")}</span>
            </p>
          </div>
          <div className={styled.cards}>
            {Data?.map((elem) => (
              <div
                className={styled.card}
                onClick={() => {
                  window.localStorage.setItem("Services", elem.id);
                  window.location = "/details";
                }}
              >
                <div className={styled.texts}>
                  <h2>
                    {Lang == "uz"
                      ? elem.services_name_uz
                      : Lang == "ru"
                      ? elem.services_name_ru
                      : Lang == "en"
                      ? elem.services_name_en
                      : Lang == "tu"
                      ? elem.services_name_tr
                      : null}
                  </h2>
                </div>
                <div className={styled.img}>
                  <div className={styled.icon}>
                    <ion-icon name="trending-up-outline"></ion-icon>
                  </div>
                  <img src={elem.image} alt="photo" />
                </div>
              </div>
            ))}
          </div>
        </WrapperContainer>
      </div>
    </>
  );
}

export default Services;
