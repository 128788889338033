import React, { useEffect } from "react";
import ContactComp from "../../components/Contact";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ContactComp />
    </>
  );
}

export default Contact;
