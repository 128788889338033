import React, { useEffect } from "react";
import styled from "./style.module.css";
import { WrapperContainer } from "../../../App-styled";
import Img1 from "../../../assets/Details/landscape 1(1).png";
import Img2 from "../../../assets/Details/industry 1.png";
import Img3 from "../../../assets/Details/bus-stop 1.png";
import { useDispatch, useSelector } from "react-redux";
import { GetServices } from "../../../redux/SubServices";
import { useTranslation } from "react-i18next";

function Infors() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetServices());
  }, []);
  const ServicesId = window.localStorage.getItem("Services");
  const Data = useSelector((state) => state.services.getServices.Data.data);
  const Lang = window.localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  return (
    <>
      <WrapperContainer>
        {Data?.map((elem) =>
          elem.services.id == ServicesId ? (
            <>
              <div className={styled.Wrapper}>
                <h2> {t("Details.1")} </h2>
                <p className={styled.subtext}>
                  {Lang == "uz"
                    ? elem.description_uz
                    : Lang == "ru"
                    ? elem.description_ru
                    : Lang == "en"
                    ? elem.description_en
                    : null}
                </p>
                <img className={styled.mainImg} src={elem.img1} alt="photo" />
                <p className={styled.subtext}>
                  {Lang == "uz"
                    ? elem.description_uz2
                    : Lang == "ru"
                    ? elem.description_ru2
                    : Lang == "en"
                    ? elem.description_en2
                    : null}
                </p>
                <div className={styled.miniImgs}>
                  <img src={elem.img2} alt="photo" />
                </div>
                <h3 className={styled.mainH3}> {t("Details.2")} </h3>
                <p className={styled.subtext}>{t("Details.3")}</p>
                <div className={styled.stages}>
                  <ul>
                    <li>
                      <div className={styled.ImgStage}>
                        <p className={styled.number}>01</p>
                        <img src={Img1} alt="photo" />
                      </div>
                      <h3> {t("Details.4")} </h3>
                      <p>{t("Details.5")}</p>
                    </li>
                    <li>
                      <div className={styled.ImgStage}>
                        <p className={styled.number}>02</p>
                        <img src={Img2} alt="photo" />
                      </div>
                      <h3> {t("Details.6")} </h3>
                      <p>{t("Details.7")}</p>
                    </li>
                    <li>
                      <div className={styled.ImgStage}>
                        <p className={styled.number}>03</p>
                        <img src={Img3} alt="photo" />
                      </div>
                      <h3> {t("Details.8")} </h3>
                      <p>{t("Details.9")}</p>
                    </li>
                  </ul>
                </div>
                <img className={styled.mainImg} src={elem.img3} alt="photo" />

                {/* <h3 className={styled.mainH3}>Tips & Tricks</h3> */}
                <p className={styled.subtext}>
                  {Lang == "uz"
                    ? elem.description_uz3
                    : Lang == "ru"
                    ? elem.description_ru3
                    : Lang == "en"
                    ? elem.description_en3
                    : null}
                </p>
              </div>
            </>
          ) : null
        )}
      </WrapperContainer>
    </>
  );
}

export default Infors;
