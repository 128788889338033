import React from "react";
import Img1 from "../../../assets/news/1.png";
import Img2 from "../../../assets/news/2.png";
import Img3 from "../../../assets/news/3.png";
import Img4 from "../../../assets/news/4.png";
import Img5 from "../../../assets/news/5.png";
import Img6 from "../../../assets/news/6.png";
import Img7 from "../../../assets/news/7.png";
import styled from "./style.module.css";
import { WrapperContainer } from "../../../App-styled";
function FooterLogos() {
  return (
    <>
      <WrapperContainer>
        <div className={styled.Wrapper}>
          <img src={Img1} alt="photo" />
          <img src={Img2} alt="photo" />
          <img src={Img3} alt="photo" />
          <img src={Img4} alt="photo" />
          <img src={Img5} alt="photo" />
          <img src={Img6} alt="photo" />
          <img src={Img7} alt="photo" />
        </div>
      </WrapperContainer>
    </>
  );
}

export default FooterLogos;
