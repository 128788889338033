import AboutUs from "../pages/AboutUs";
import BlogDetails from "../pages/BlogDetails";
import Blog from "../pages/BlogStandart";
import Contact from "../pages/Contact";
import Details from "../pages/Details";
import Home from "../pages/Home";
import More from "../pages/More";
import Project from "../pages/Project";
import Service from "../pages/Service";

export const DataRouters = [
  {
    id: 1,
    path: "/",
    Element: <Home />,
  },
  {
    id: 2,
    path: "/aboutus",
    Element: <AboutUs />,
  },
  {
    id: 3,
    path: "/service",
    Element: <Service />,
  },
  {
    id: 4,
    path: "/details",
    Element: <Details />,
  },
  {
    id: 5,
    path: "/project",
    Element: <Project />,
  },
  {
    id: 6,
    path: "/project/:id",
    Element: <Project />,
  },
  {
    id: 7,
    path: "/more",
    Element: <More />,
  },
  {
    id: 8,
    path: "/blog",
    Element: <Blog />,
  },
  {
    id: 9,
    path: "/blogdetails",
    Element: <BlogDetails />,
  },
  {
    id: 10,
    path: "/contact",
    Element: <Contact />,
  },
];
