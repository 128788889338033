import React from "react";
import CommonHeader from "../common/commonHeader";
import BackImg from "../../assets/BlogStandart/110.png";
import MoreComment from "./MoreComment";
import BlogRequest from "./Request";
import { useTranslation } from "react-i18next";
function BlogDetailsComp() {
  const { t } = useTranslation();
  return (
    <>
      <CommonHeader
        title={t("Blog.5")}
        title1={t("Blog.5")}
        backImage={BackImg}
      />
      <MoreComment />
      <BlogRequest />
    </>
  );
}

export default BlogDetailsComp;
