import React, { useEffect } from "react";
import ServiceComp from "../../components/Service";

function Service() {
  useEffect(() => {
    window.scrollTo(1, 0);
  }, []);
  return (
    <>
      <ServiceComp />
    </>
  );
}

export default Service;
