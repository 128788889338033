import React from "react";
import CommonHeader from "../common/commonHeader";
import BackImg from "../../assets/AboutUs/31.png";
import Gardening from "./Gardining";
import Modern from "./Modern";
import Experts from "./Experts";
import Needs from "./Need";
import Global from "./Global";
import { useTranslation } from "react-i18next";

function AboutUsComp() {
  const { t } = useTranslation();
  return (
    <>
      <CommonHeader
        title={t("AboutUs.0")}
        title1={t("AboutUs.0")}
        backImage={BackImg}
      />
      <Gardening />
      <Modern />
      <Global />/
    </>
  );
}

export default AboutUsComp;
