import { styled } from "styled-components";

export const Card = styled.div`
  background: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: 125% !important;
  background-position: center;
  transition: 0.65s;
  width: 97%;
  border-radius: 20px;
  cursor: pointer;
  padding: 2%;
  transition: 0.65s;
  margin: 10% auto 0% !important;
  &:hover {
    background-size: 140% !important;
  }
`;
