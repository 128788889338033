import React, { useState } from "react";
import { WrapperContainer } from "../../../App-styled";
import HeaderMenu from "./headerHamburger";
import styled from "./style.module.css";
import { WrapperBack } from "./styled-index";
import { NavLink } from "react-router-dom";
import Logo from "../../../assets/Header/logo.png";
import { useTranslation } from "react-i18next";
function CommonHeader({ title, backImage, title1 }) {
  const [modal, setModal] = useState(false);
  const HandelOpen = (e) => {
    setModal(true);
  };
  const HandelClose = (e) => {
    setModal(false);
  };
  const { t } = useTranslation();
  const ValLang = () => {
    return window.localStorage.getItem("i18nextLng");
  };
  const HendelChange = (e) => {
    e.preventDefault();
    const LanVal = e.target.value;
    window.localStorage.setItem("i18nextLng", LanVal);
    window.location.reload();
  };

  return (
    <>
      <div className={styled.Wrapper}></div>
      <WrapperBack img={backImage}>
        <WrapperContainer>
          <div className={styled.WrapperMain}>
            <div className={styled.Main}>
              <div className={styled.texts}>
                <h2>{title1}</h2>
              </div>
              <div className={styled.buttons}>
                <p>{t("Header.5")}</p>
                <i class="bx bx-radio-circle-marked"></i>
                <p>{title}</p>
              </div>
            </div>
          </div>
        </WrapperContainer>
        <HeaderMenu
          ValLang={ValLang}
          HendelChange={HendelChange}
          modal={modal}
          HandelClose={HandelClose}
        />
      </WrapperBack>
    </>
  );
}

export default CommonHeader;
