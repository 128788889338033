import { styled } from "styled-components";

export const MainWrapper = styled.div`
  position: fixed;
  width: 100%;
  z-index: 1000;
`;

export const TopHeader = styled.div`
  overflow: hidden;
  transition: 0.5s;
  height: ${(props) => (props.head ? "10vh" : "0vh")};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media screen and (max-width: 1000px) {
    height: ${(props) => (props.head ? "0vh" : "0vh")};
  }
`;
