import React, { useEffect } from "react";
import BlogComp from "../../components/BlogStandart";

function Blog() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <BlogComp />
    </>
  );
}

export default Blog;
