import React, { useEffect } from "react";
import { WrapperContainer } from "../../../App-styled";
import Plant from "../../../assets/Services/5.png";
import styled from "./style.module.css";
import img1 from "../../../assets/Services/1.png";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { GetComments } from "../../../redux/comments";
import { NavLink } from "react-router-dom";

function Servicess() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetComments());
  }, []);
  const Data = useSelector((state) => state.comments.getComments.Data.data);
  const Lang = window.localStorage.getItem("i18nextLng");
  return (
    <>
      <div className={styled.WrapperBack}>
        <WrapperContainer>
          <div className={styled.Wrapper}>
            <p>
              <img src={Plant} alt="photo" />
              <span>{t("Servies.0")}</span>
            </p>
          </div>
          <div className={styled.cards}>
            {Data?.map((elem) => (
              <div
                className={styled.card}
                onClick={async () => {
                  await window.localStorage.setItem("Services", elem.id);
                  window.location = "/details";
                }}
              >
                <div className={styled.texts}>
                  {/* <img src={img1} alt="photo" /> */}
                  <h2>
                    {Lang == "uz"
                      ? elem.services_name_uz
                      : Lang == "ru"
                      ? elem.services_name_ru
                      : Lang == "en"
                      ? elem.services_name_en
                      : Lang == "tu"
                      ? elem.services_name_tr
                      : null}
                  </h2>
                </div>
                <div className={styled.img}>
                  <div className={styled.icon}>
                    <ion-icon name="trending-up-outline"></ion-icon>
                  </div>
                  <img src={elem.image} alt="photo" />
                </div>
              </div>
            ))}
          </div>
        </WrapperContainer>
      </div>
    </>
  );
}

export default Servicess;
