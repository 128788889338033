import React, { useEffect } from "react";
import MoreComp from "../../components/More";

function More() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <MoreComp />
    </>
  );
}

export default More;
