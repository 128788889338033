import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils";
export const GetCategory = createAsyncThunk("Category/get", async () => {
  return axios.get(`${API_URL}/category`).then((res) => res);
});

const CategorySlice = createSlice({
  name: "Category",
  initialState: {
    getCategory: {
      Error: false,
      Loading: false,
      Success: false,
      Data: [],
    },
  },
  extraReducers: {
    [GetCategory.pending]: (state, action) => {
      state.getCategory.Loading = true;
    },
    [GetCategory.fulfilled]: (state, action) => {
      state.getCategory.Error = false;
      state.getCategory.Success = true;
      state.getCategory.Loading = false;
      state.getCategory.Data = action.payload;
    },
    [GetCategory.rejected]: (state, action) => {
      state.getCategory.Error = true;
      state.getCategory.Success = false;
      state.getCategory.Loading = false;
      state.getCategory.Data = [];
    },
  },
});

export default CategorySlice.reducer;
