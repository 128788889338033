import { NavLink } from "react-router-dom";
import { Menu } from "./styled-index";
import styled from "./style.module.css";
import { useTranslation } from "react-i18next";

function HeaderMenu({ modal, HandelClose, ValLang, HendelChange }) {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Menu modal={modal === false}>
        <div className="close">
          <i onClick={HandelClose} class="bx bx-x"></i>
        </div>
        <ul>
          <li>
            <NavLink onClick={HandelClose} to={"/aboutus"}>
              {t("Header.0")}
            </NavLink>
          </li>
          <li>
            <NavLink onClick={HandelClose} to={"/service"}>
              {t("Header.1")}
            </NavLink>
          </li>
          <li>
            <NavLink onClick={HandelClose} to={"/project"}>
              {t("Header.2")}
            </NavLink>
          </li>
          <li>
            <NavLink onClick={HandelClose} to={"/blog"}>
              {t("Header.3")}
            </NavLink>
          </li>
          <li>
            <NavLink onClick={HandelClose} to={"/contact"} href="#">
              {t("Header.4")}
            </NavLink>
          </li>
          <li>
            <select onChange={HendelChange}>
              {ValLang() === "uz" ? (
                <>
                  <option value="uz">Uzbek</option>
                  <option value="ru">Russian</option>
                  <option value="en">English</option>
                  <option value="tu">Turkey</option>
                </>
              ) : ValLang() === "ru" ? (
                <>
                  <option value="ru">Russian</option>
                  <option value="uz">Uzbek</option>
                  <option value="en">English</option>
                  <option value="tu">Turkey</option>
                </>
              ) : ValLang() === "en" ? (
                <>
                  <option value="en">English</option>
                  <option value="uz">Uzbek</option>
                  <option value="ru">Russian</option>
                  <option value="tu">Turkey</option>
                </>
              ) : ValLang() === "tu" ? (
                <>
                  <option value="tu">Turkey</option>
                  <option value="en">English</option>
                  <option value="uz">Uzbek</option>
                  <option value="ru">Russian</option>
                </>
              ) : null}
            </select>
          </li>
        </ul>
        <div className={styled.Ul}>
          <div className={styled.li}>
            <div className={styled.icon}>
              <ion-icon name="location-outline"></ion-icon>
            </div>
            <div className={styled.texts}>
              <p>{t("InforHeader.0")}</p>
              <h3>55 Мэйн Стрит, США</h3>
            </div>
          </div>
          <div className={styled.li}>
            <div className={styled.icon}>
              <ion-icon name="mail-outline"></ion-icon>
            </div>
            <div className={styled.texts}>
              <p>{t("InforHeader.1")}</p>
              <a href="mailto:info@nemagardens.com">
                <h3>info@nemagardens.com</h3>
              </a>
            </div>
          </div>
        </div>
        <div className={styled.li}>
          <div className={styled.icon}>
            <ion-icon name="call-outline"></ion-icon>
          </div>
          <div className={styled.texts}>
            <p>{t("InforHeader.2")}</p>
            <h3>+998-78-888-88-22</h3>
          </div>
        </div>
      </Menu>
    </>
  );
}

export default HeaderMenu;
