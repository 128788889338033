import React, { useEffect, useState } from "react";
import { WrapperContainer } from "../../../App-styled";
import styled from "./style.module.css";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { GetComments } from "../../../redux/comments/index";
import { GetCategory } from "../../../redux/category";
import "./style.css";

function Comments() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetComments());
    dispatch(GetCategory());
  }, []);
  const DataCateg = useSelector(
    (state) => state.comments.getComments.Data.data
  );
  const DataCate = useSelector((state) => state.category.getCategory.Data.data);
  const lang = window.localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  function Items({ currentItems }) {
    return (
      <>
        {currentItems &&
          currentItems.map((elem) => (
            <NavLink
              to={"/blogdetails"}
              onClick={() => window.localStorage.setItem("BlogId", elem.id)}
            >
              <div className={styled.boxComment}>
                <img className={styled.mainImg} src={elem.image} alt="photo" />
                <div className={styled.infors}>
                  <h2>{elem.title_uz}</h2>
                  <p>{elem.description_uz}</p>
                </div>
              </div>
            </NavLink>
          ))}
      </>
    );
  }

  function PaginatedItems({ itemsPerPage }) {
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = DataCateg?.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(DataCateg?.length / itemsPerPage);

    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % DataCateg?.length;
      setItemOffset(newOffset);
    };

    return (
      <>
        <Items currentItems={currentItems} />
        <ReactPaginate
          breakLabel="..."
          nextLabel={<i class="bx bx-chevrons-right"></i>}
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel={<i class="bx bx-chevrons-left"></i>}
          renderOnZeroPageCount={null}
        />
      </>
    );
  }

  return (
    <>
      <WrapperContainer>
        <div className={styled.Wrapper}>
          <div className={styled.comments}>
            <PaginatedItems itemsPerPage={4} />
          </div>
          <div className={styled.pluss}>
            <div className={styled.categories}>
              <h2>{t("Blog.1")}</h2>
              <ul>
                {DataCate?.slice(0, 5).map((elem) => (
                  <li>
                    <NavLink to={"/project/4"}>
                      <divq>
                        <h3>
                          {lang == "ru"
                            ? elem.category_name_ru
                            : lang == "uz"
                            ? elem.category_name_uz
                            : lang == "en"
                            ? elem.category_name_en
                            : lang == "tu"
                            ? elem.category_name_tr
                            : null}
                        </h3>
                        <i class="bx bx-chevrons-right"></i>
                      </divq>
                      <div></div>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
            <div className={styled.contactUs}>
              <h2>{t("Blog.2")}</h2>
              <p>{t("Blog.3")}</p>
              <NavLink to={"/contact"}>
                <button>{t("Blog.4")}</button>
              </NavLink>
            </div>
          </div>
        </div>
      </WrapperContainer>
    </>
  );
}

export default Comments;
